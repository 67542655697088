import React, { useMemo, useState, useEffect } from "react";

type ViewportSize = {
  width: number;
  height: number;
};

type ViewportInfoContextValue = {
  viewportSize: ViewportSize;
};

const initialViewportSize = {
  width: 0,
  height: 0,
};

const ViewportInfoContext =
  React.createContext<ViewportInfoContextValue | null>(null);

export const ViewportInfoProvider: React.FC = ({ children }) => {
  const [viewportSize, setViewportSize] =
    useState<ViewportSize>(initialViewportSize);

  useEffect(() => {
    function getViewportSize() {
      setViewportSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", getViewportSize);
    window.addEventListener("orientationchange", getViewportSize);
    getViewportSize();
    return () => {
      window.removeEventListener("resize", getViewportSize);
      window.removeEventListener("orientationchange", getViewportSize);
    };
  }, []);

  const value = useMemo(
    (): ViewportInfoContextValue => ({ viewportSize }),
    [viewportSize],
  );

  return (
    <ViewportInfoContext.Provider value={value}>
      {children}
    </ViewportInfoContext.Provider>
  );
};

export const useViewportInfo = () => {
  const context = React.useContext(ViewportInfoContext);

  if (context === undefined) {
    throw new Error(
      "useViewportInfo must be used within a ViewportInfoProvider",
    );
  }

  return context?.viewportSize || initialViewportSize;
};
