const getEnv = (envName: string): string => {
  const env = process.env[envName];
  if (!env) throw new Error(`${envName} is not defined.`);
  return env;
};

export type PortalVariant = "accountant" | "customer";

export const CLIENT_VERSION = getEnv("REACT_APP_VERSION");

export const gettingStartedMaxClients = 5;
export const variant = getEnv("REACT_APP_PORTAL_VARIANT") as PortalVariant;

// List of feature flags supported by the portal. These feature flags are defined in the
// backend through the Django-admin under "django-waffle".
export const FEATURE_FLAGS = {
  showAccountantTaxTab: "portal.show_accountant_tax_tab",
  webAddBankAccounts: "web.add_bank_accounts",
  showMtdTab: "portal.show_mtd_tab",
  showMtdInfo: "portal.show_mtd_feature_stub",
  showReceiptsTab: "portal.show_receipts_tab",
};

export const typeformFormIds = {
  mtdFeature: "yFsIKhzo",
};

const customerWebHost =
  process.env.NODE_ENV === "production"
    ? "https://web.getcoconut.com"
    : "http://localhost:3000";

const accountantWebHost =
  process.env.NODE_ENV === "production"
    ? "https://accountant.getcoconut.com"
    : "http://localhost:3000";

export const currentWebRoot =
  variant === "customer" ? customerWebHost : accountantWebHost;

// Global config defaults - can be overridden on a per-query basis.
export const reactQueryConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 15,
      gcTime: 1000 * 60 * 60 * 24,
      refetchOnWindowFocus: false,
    },
  },
};

export const ACCOUNT_SERVICE_BASE_URL =
  process.env.REACT_APP_ACCOUNT_SERVICE_URL;
