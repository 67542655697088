import { FEATURE_FLAGS } from "src/config";
import {
  createAuthProvider,
  AuthState as BaseAuthState,
} from "src/providers/createAuthProvider";
import { LegalSetup } from "src/utils/constants";
import { customerLoginSubmit, getCustomerMe } from "src/api/session";

type FeatureFlag = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];

type Customer = {
  id: string;
  legalSetup?: LegalSetup;
  is_direct_user: boolean;
  is_multiple_accountant_firms: boolean;
  transaction_lock_date: Date | null;
};

type IntercomParams = {
  userId: string;
  name: string;
};

type User = {
  id: string;
  email: string;
  intercomParams?: IntercomParams;
  featureFlags: FeatureFlag[];
  isTermsAccepted: boolean;
};

type AuthState = {
  user: User;
  customer?: Customer;
};

export type CustomerMeData = {
  is_multiple_accountant_firms: boolean;
  transaction_lock_date: Date | null;
  id: string;
  user_id: string;
  legal_setup: LegalSetup | null;
  is_direct_user: boolean;
  intercom_params: {
    user_id: string;
    email: string;
    name: string;
  };
  feature_flags: FeatureFlag[];
  accept_terms: boolean;
};

const transformCurrentUserToContextValue = (
  meData: CustomerMeData,
): AuthState => ({
  user: {
    id: meData.user_id,
    email: meData.intercom_params.email,
    intercomParams: {
      userId: meData.intercom_params.user_id,
      name: meData.intercom_params.name,
    },
    featureFlags: meData.feature_flags,
    isTermsAccepted: meData.accept_terms,
  },
  customer: {
    id: meData.id,
    is_direct_user: meData.is_direct_user,
    is_multiple_accountant_firms: meData.is_multiple_accountant_firms,
    transaction_lock_date: meData.transaction_lock_date,
    ...(meData.legal_setup ? { legalSetup: meData.legal_setup } : {}),
  },
});

const { AuthProvider, useAuth } = createAuthProvider<AuthState, CustomerMeData>(
  {
    loginSuccessUrl: "/bookkeeping",
    logoutSuccessUrl: "/login",
    loginFn: customerLoginSubmit,
    transformCurrentUserToContextValue,
    getMeFn: getCustomerMe,
  },
);

export type CustomerAuthState = AuthState & BaseAuthState;

export { AuthProvider, useAuth };
