import React, { useMemo } from "react";
import { PortalVariant } from "src/config";

type PortalVariantContextValue = {
  variant: PortalVariant;
};

const PortalVariantContext =
  React.createContext<PortalVariantContextValue | null>(null);

type VariantProviderProps = {
  variant: PortalVariant;
  children: React.ReactNode;
};

const PortalVariantProvider = ({ variant, children }: VariantProviderProps) => {
  const value = useMemo(
    (): PortalVariantContextValue => ({ variant }),
    [variant],
  );

  return (
    <PortalVariantContext.Provider value={value}>
      {children}
    </PortalVariantContext.Provider>
  );
};

const usePortalVariant = () => {
  const context = React.useContext(PortalVariantContext);

  if (context === undefined) {
    throw new Error(
      "`usePortalVariant` must be used within a `PortalVariantProvider`",
    );
  }

  // This fallback shouldn't ever really occur. Keeping TS happy
  // so we don't have to `as PortalVariant` everywhere we use the hook
  return context?.variant || "accountant";
};

export { PortalVariantProvider, usePortalVariant };
