import { FEATURE_FLAGS } from "src/config";
import {
  createAuthProvider,
  AuthState as BaseAuthState,
} from "src/providers/createAuthProvider";
import { accountantLogin, getAccountantMe } from "src/api/session";

export type FeatureFlag = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];

type Advisor = {
  id: string;
  firmName: string;
  firmId: string;
  fullName: string;
  isAdmin: boolean;
};

type IntercomParams = {
  userId: string;
  name: string;
};

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  hasSeenOnboardingVideo: boolean;
  intercomParams?: IntercomParams;
  featureFlags: FeatureFlag[];
  isTermsAccepted: boolean;
};

type AuthState = {
  user: User;
  advisor: Advisor;
};

export type AccountantMeData = {
  id: string;
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  meta: {
    hasSeenOnboardingVideo: boolean;
  };
  firm_name: string;
  firm_id: string;
  intercom_params: {
    user_id: string;
    name: string;
  };
  feature_flags: FeatureFlag[];
  accept_terms: boolean;
  user_groups: string;
};

const transformCurrentUserToContextValue = (
  meData: AccountantMeData,
): AuthState => ({
  user: {
    id: meData.user_id,
    email: meData.email,
    firstName: meData.first_name,
    lastName: meData.last_name,
    hasSeenOnboardingVideo: meData.meta.hasSeenOnboardingVideo,
    intercomParams: {
      userId: meData.intercom_params.user_id,
      name: meData.intercom_params.name,
    },
    featureFlags: meData.feature_flags,
    isTermsAccepted: meData.accept_terms,
  },
  advisor: {
    id: meData.id,
    firmName: meData.firm_name,
    firmId: meData.firm_id,
    fullName: meData.full_name,
    isAdmin: meData.user_groups === "advisor_admin",
  },
});

const { AuthProvider, useAuth } = createAuthProvider<
  AuthState,
  AccountantMeData
>({
  loginSuccessUrl: "/clients",
  logoutSuccessUrl: "/login",
  loginFn: accountantLogin,
  transformCurrentUserToContextValue,
  getMeFn: getAccountantMe,
});

export type AccountantAuthState = AuthState & BaseAuthState;

export { AuthProvider, useAuth };
