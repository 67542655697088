import axios, { AxiosHeaders, AxiosHeaderValue } from "axios";
import { hmrcFraudHeaders } from "src/utils/hmrc/fraudHeaders";
import { CLIENT_VERSION, variant } from "src/config";
import { ME_ENDPOINT } from "src/api/session";

export const API_BASE_URL =
  process.env.REACT_APP_COCONUT_ACCOUNTANT_PORTAL_BASE_URL;

const coconutUserRole = variant;

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE_OPTIONS = [50, 100];
export const DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE_OPTIONS[0];

const baseAxiosConfig = {
  baseURL: `${API_BASE_URL}`,
  headers: {
    "X-Api-Platform": "WEB",
    "X-Coconut-User-Role": coconutUserRole,
    "X-Coconut-Client-Version": CLIENT_VERSION,
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withCredentials: true,
};

const apiClient = axios.create(baseAxiosConfig);

// Use an interceptor to run common operations before every request
// https://github.com/axios/axios#interceptors
apiClient.interceptors.request.use(async function (config) {
  // Get a CSRF token for the request before modifying API calls
  // COCO-10047
  if (
    config.method &&
    ["post", "put", "patch", "delete"].includes(config.method)
  ) {
    await apiClient.get("/accountant_portal_api/csrf/").then((response) => {
      const csrfToken = response.data["csrftoken"];
      config.headers = new AxiosHeaders(config.headers);
      config.headers.set(apiClient.defaults.xsrfHeaderName, csrfToken);
    });
  }

  // Inject HRMC fraud headers if initialized (WEB-225)
  if (hmrcFraudHeaders) {
    config.headers = new AxiosHeaders(config.headers);
    Object.entries(hmrcFraudHeaders).forEach(([key, value]) => {
      config.headers.set(key, value as AxiosHeaderValue);
    });
  }

  return config;
});

export const setupPermissionDeniedInterceptor = (
  handlePermissionDenied: () => Promise<void>,
) => {
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      // The meEndpoint is expected to return HTTP 403 if the
      // user is unauthenticated. In this case the handler
      // function does not need to be called.
      if (error.response?.status === 403) {
        if (!error.response.request.responseURL.includes(ME_ENDPOINT)) {
          await handlePermissionDenied();
        }
      }
      return Promise.reject(error);
    },
  );
};

export default {
  apiClient,
};
