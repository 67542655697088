import mixpanel from "mixpanel-browser";
import { variant } from "src/config";

const initialise = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);
};

const trackEvent = (
  event: string,
  properties: Record<string, unknown> | undefined = undefined,
  options = undefined,
) => {
  const eventId = `${variant}_portal_${event}`;
  mixpanel.track(eventId, properties, options);
};

const identify = (userId: string) => mixpanel.identify(userId);

const reset = () => mixpanel.reset();

export default {
  initialise,
  trackEvent,
  identify,
  reset,
};
