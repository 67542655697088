import { Navigate, RouteProps, useLocation } from "react-router-dom";

type ProtectedRouteProps = RouteProps & {
  isAllowed: boolean;
  redirectToIfNotAllowed: string;
  includeRedirectQueryParam?: boolean;
};

const ProtectedRoute = ({
  isAllowed,
  redirectToIfNotAllowed,
  includeRedirectQueryParam = false,
  children,
}: ProtectedRouteProps) => {
  const location = useLocation();
  const encodedURL = encodeURIComponent(location.search);

  // N.B. Only insert a redirect query parameter if it's more than '/'.
  const unauthenticatedRedirectQueryParam =
    location.pathname.length > 1 && includeRedirectQueryParam
      ? `?redirect=${location.pathname}${encodedURL}`
      : "";

  return isAllowed ? (
    <>{children}</>
  ) : (
    <Navigate
      to={`${redirectToIfNotAllowed}${unauthenticatedRedirectQueryParam}`}
    />
  );
};

export default ProtectedRoute;
