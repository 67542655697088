import * as Sentry from "@sentry/react";
import { getFraudPreventionHeaders } from "user-data-for-fraud-prevention";

const DEVICE_ID_STORAGE_KEY = "DEVICE_ID";

export let hmrcFraudHeaders = {};

const prepareMTDFraudHeaders = async () => {
  const fraudData = await getFraudPreventionHeaders();
  const existingDeviceId = window.localStorage.getItem(DEVICE_ID_STORAGE_KEY);

  const allHeaders = Object.fromEntries(fraudData.headers);

  const applicableHeaders = {
    ...(allHeaders["Gov-Client-Browser-Do-Not-Track"] && {
      ["Gov-Client-Browser-Do-Not-Track"]:
        allHeaders["Gov-Client-Browser-Do-Not-Track"],
    }),
    ...(allHeaders["Gov-Client-Browser-JS-User-Agent"] && {
      ["Gov-Client-Browser-JS-User-Agent"]:
        allHeaders["Gov-Client-Browser-JS-User-Agent"],
    }),
    ...((!!existingDeviceId || !!allHeaders["Gov-Client-Device-ID"]) && {
      ["Gov-Client-Device-ID"]:
        existingDeviceId || allHeaders["Gov-Client-Device-ID"],
    }),
    ...(allHeaders["Gov-Client-Screens"] && {
      ["Gov-Client-Screens"]: allHeaders["Gov-Client-Screens"],
    }),
    ...(allHeaders["Gov-Client-Timezone"] && {
      ["Gov-Client-Timezone"]: allHeaders["Gov-Client-Timezone"],
    }),
    ...(allHeaders["Gov-Client-Window-Size"] && {
      ["Gov-Client-Window-Size"]: allHeaders["Gov-Client-Window-Size"],
    }),
  };

  // Persist device ID for subsequent sessions
  if (!existingDeviceId && applicableHeaders["Gov-Client-Device-ID"]) {
    window.localStorage.setItem(
      DEVICE_ID_STORAGE_KEY,
      applicableHeaders["Gov-Client-Device-ID"],
    );
  }

  return applicableHeaders;
};

prepareMTDFraudHeaders()
  .then((headers) => {
    hmrcFraudHeaders = headers;
  })
  .catch((error) => {
    console.error("Error generating HMRC headers", error);
    Sentry.captureException(error);
  });
