import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Intercom, Mixpanel } from "src/utils/analytics";
import Loading from "src/components/Loading";
import GlobalErrorBoundary from "src/utils/sentry/GlobalErrorBoundary";
import reactLazyWithRetries from "src/utils/reactLazyWithRetries";
import "src/scss/style.scss";
import { useDocumentTitle } from "src/utils/hooks";
import ProtectedRoute from "src/components/ProtectedRoute";
import InjectAxiosInterceptors from "src/components/InjectAxiosInterceptors";
import { PortalVariantProvider } from "src/hooks/usePortalVariant";
import { ToastHost } from "src/components/Toast/ToastHost";
import { ViewportInfoProvider } from "src/hooks/useViewportInfo";
import { useAuth, AuthProvider, AccountantAuthState } from "./AuthProvider";

Intercom.initialise();
Intercom.boot();

Mixpanel.initialise();

// Screens
// N.B. the `/* webpackChunkName: "xyz" */` comments specify the filename prefix of the
// JS chunk files that webpack generates.
const LoggedIn = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "LoggedIn" */ "src/apps/accountant/screens/LoggedIn"
    ),
);
const Login = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "Login" */ "src/apps/accountant/screens/Login"),
);
const ForgotPassword = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "ForgotPassword" */ "src/apps/accountant/screens/ForgotPassword"
    ),
);
const ResetPassword = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "ResetPassword" */ "src/apps/accountant/screens/ResetPassword"
    ),
);
const SignUp = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "SignUp" */ "src/apps/accountant/screens/SignUp"
    ),
);

// we have a route for this just so product can look at it easily
const ErrorFallback = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "ErrorFallback" */ "src/screens/ErrorFallback"),
);

const AppRoutes = () => {
  const { isAuthenticated } = useAuth() as AccountantAuthState;

  return (
    <Routes>
      <Route path="/500" element={<ErrorFallback />} />
      <Route
        path="/sign-up"
        element={
          <ProtectedRoute
            isAllowed={!isAuthenticated}
            redirectToIfNotAllowed="/clients"
          >
            <SignUp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <ProtectedRoute
            isAllowed={!isAuthenticated}
            redirectToIfNotAllowed="/clients"
          >
            <Login />
          </ProtectedRoute>
        }
      />
      <Route
        path="/forgot"
        element={
          <ProtectedRoute
            isAllowed={!isAuthenticated}
            redirectToIfNotAllowed="/clients"
          >
            <ForgotPassword />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <ProtectedRoute
            isAllowed={!isAuthenticated}
            redirectToIfNotAllowed="/clients"
          >
            <ResetPassword />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute
            isAllowed={isAuthenticated}
            redirectToIfNotAllowed="/login"
          >
            <LoggedIn />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export type AccountantAppProps = {
  queryClient: QueryClient;
};

const AccountantApp: React.FC<AccountantAppProps> = ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  useDocumentTitle();

  return (
    <ViewportInfoProvider>
      <PortalVariantProvider variant="accountant">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <GlobalErrorBoundary>
              <AuthProvider>
                <InjectAxiosInterceptors useAuthHook={useAuth} />
                <Suspense fallback={<Loading />}>
                  <AppRoutes />
                </Suspense>
                <ToastHost />
              </AuthProvider>
            </GlobalErrorBoundary>
          </BrowserRouter>
          {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        </QueryClientProvider>
      </PortalVariantProvider>
    </ViewportInfoProvider>
  );
};

export default AccountantApp;
